

export const setToken  = (value) => { localStorage.setItem('token', value); };

export const getToken = () => localStorage.getItem('token');

export const hasToken = () => localStorage.getItem('token') != null;

export const resetToken = () => setToken(null);

export const setUsername  = (value) => { localStorage.setItem('username', value); };

export const getUsername = () => localStorage.getItem('username');

export const hasUsername = () => localStorage.getItem('username') != null;

export const resetUsername = () => setUsername(null);



